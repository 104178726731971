import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import axios from 'axios'
import validator from 'validator'

import "../../node_modules/antd/dist/antd.css"
import { Modal, Button, Radio } from 'antd';
import "../css/rahul-styles.css"

const PopupForm = (props) => {

  const {time, handleTimeChange} = props 


  useEffect(() => {
    setTimeout(() => {
      showModal()
    }, time)
  }, [time])

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [qualification, setQualification] = useState('')
  const [destination, setDestination] = useState('')
  const [abroad, setAbroad] = useState('')

  const [questions, setQuestions] = useState('')
  const [policy, setPolicy] = useState(false) 
  const [formError, setFormError] = useState({})
  const errors = {}

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };



  const handleChange = (e) => {
    if(e.target.name === 'firstName') {
      setFirstName(e.target.value)
    } else if (e.target.name === 'lastName') {
      setLastName(e.target.value)
    } else if (e.target.name === 'email') {
      setEmail(e.target.value)
    } else if (e.target.name === 'number') {
      setNumber(e.target.value)
    } else if (e.target.name === 'qualification') {
      setQualification(e.target.value)
    } else if (e.target.name === 'destination') {
      setDestination(e.target.value)
    } else if (e.target.name === 'questions') {
      setQuestions(e.target.value)
    } else if (e.target.name === 'abroad') {
      setAbroad(e.target.value)
    }
  }

  const handlePolicy = (e) => {
    const policyInput = e.target.checked 
    setPolicy(policyInput) 
  }

  const runValidations = () => {
    if(firstName.trim().length === 0) {
      errors.firstName = "Required"
    } 
   
    if (email.trim().length === 0) {
      errors.email = "Required"
    } else if (!validator.isEmail(email)) {
      errors.email = "Invalid E-Mail Format"
    }
    if (number.trim().length === 0) {
      errors.number = "Required"
    } else if (number.trim().length < 10) {
      errors.number = "Atleast 10 digits required"
    } 
    if (!qualification) {
      errors.qualification = "Required"
    }
    if (!destination) {
      errors.destination = "Required"
    }
  
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    runValidations()



    if(Object.keys(errors).length === 0) {
      setFormError({})
      let lead_captured_at;

      if (document.querySelector('#lead_captured_at').getAttribute('data-source') != "" && document.querySelector('#lead_captured_at').getAttribute('data-source') != null) {
        lead_captured_at = document.querySelector('#lead_captured_at').getAttribute('data-source');
      } else {
        lead_captured_at = "Website Enquiry";
      }
      
      const formData = {
        firstName: firstName,
 
        email: email,
        number: number,
        destination: destination,

        qualification: qualification,
  
        policy: policy,
        lead_captured_at: lead_captured_at,

      }


      setFirstName('')
  
      setEmail('')
      setNumber('')
      setDestination('')
  
      setQualification('')

      setPolicy(false)



      swal("Done!", "Our Admissions team will get in touch with you soon.", "success");

      handleCancel()



     
     



      axios.post('https://api.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r818cc16ada0b011c2981128aabe70284&secretKey=65a4a7aea451fbd17be5958bff3d23abe3bcb92f', [
        {
          "Attribute": "FirstName",
          "Value": firstName
        },
      
        {
          "Attribute": "EmailAddress",
          "Value": email
        },
        {
          "Attribute": "Phone",
          "Value": number
        },
        {
          "Attribute": "SearchBy",
          "Value": "Phone"
        },
        {
          "Attribute": "mx_Student_program_level",
          "Value": qualification
        },
        {
          "Attribute": "mx_Target_Destination",
          "Value": destination 
        },
      
        {
          "Attribute": "ProspectID",
          "Value": window ? window.MXCProspectId ? window.MXCProspectId : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx" : "xxxxxxxx-d168-xxxx-9f8b-xxxx97xxxxxx"
        },
   
        {
          "Attribute": "mx_Read_TWG_Privacy_Policy",
          "Value": policy 
        },
        {
          "Attribute": "Source",
          "Value": "Website Enquiry"
        },
        {
          "Attribute": "mx_Lead_Captured_at",
          "Value": lead_captured_at
        }
      ])
      .then(response => {
        if(lead_captured_at == "Exit Popup") {
          localStorage.setItem('isPopupShown', 'Never');
        }
      })
      .catch(err => {
        alert(err.message)
        console.log(err.message)
      })

    } else {
      setFormError(errors)
    }
  }


  return (
    <>

   <Modal className="antd-modal-main"  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={400} footer={null}>


        <div class="container  newStyle">
          <div class="row justify-content-center">
            <div class="col-12  bg-white modal-popup-main popup-form-apt-new">
              <button title="Close (Esc)" type="button" class="mfp-close">×</button>
              <div class="get-in-touch-header padding-4-rem-lr">
                <h4 class="font-weight-500 d-block margin-10px-bottom">Get in touch with us</h4>
              </div>
              <div class="row">
                <div class="col-md-6 plr-7 form-field">
                  
                  <input
                    name='firstName'
                    value={firstName}
                    onChange={handleChange}
                    type="text"
                    className="small-input"
                    placeholder='Name'
                  />
                  <label className="form-label form-label-popup">NAME* {formError.firstName && <span className="text-danger error-message-text"> {formError.firstName} </span>}</label>
                </div>

                <div class="col-md-6 plr-7 form-field">
                  <input
                    name='email'
                    value={email}
                    onChange={handleChange}
                    type="email"
                    className="small-input"
                    placeholder='Email'
                  />
                  <label className="form-label form-label-popup">E-MAIL* {formError.email && <span className="text-danger error-message-text"> {formError.email} </span>}</label>
                </div>

                <div class="col-md-12 plr-7 form-field">
                  <input
                    name='number'
                    value={number}
                    onChange={handleChange}
                    type="number"
                    className="small-input"
                    placeholder='Mobile'
                  />
                  <label className="form-label form-label-popup">PHONE NUMBER* {formError.number && <span className="text-danger error-message-text"> {formError.number} </span>}</label>
                </div>
              </div>

              <div class="row mt-1">              

                <div class="col-md-12 plr-7">
                  <label className="form-label form-label-popup"> QUALIFICATION YOU'RE SEEKING* </label>
                  <Radio.Group
                  className="small-input"
                  name="qualification"
                  value={qualification}
                  onChange={handleChange}
                 >
                    <Radio value="Undergraduate"> Undergraduate </Radio>
                    <Radio value="Postgraduate"> Postgraduate </Radio>
                  </Radio.Group>
                  
                  {formError.qualification && <span className="text-danger error-message-text"> {formError.qualification} </span>}
                </div>

                <div class="col-md-12 plr-7">
                  <label className="form-label form-label-popup"> PREFERRED EDUCATION DESTINATION* </label>

                  <Radio.Group
                  className="small-input"
                  name="destination"
                  value={destination}
                  onChange={handleChange}
                 >
                    <Radio value="Australia"> Australia </Radio>
                    <Radio value="Dubai"> Dubai </Radio>
                    <Radio value="United Kingdom"> United Kingdom </Radio>
                    <Radio value="United States"> United States </Radio>
                    <Radio value="Other"> Other </Radio>
                  </Radio.Group>
                  
                  {formError.destination && <span className="text-danger error-message-text"> {formError.destination} </span>}
                </div>

                <div class="col-md-6 plr-7 multi-select-container"></div>

              </div>

              <div class="row">
                <div class="col-12 plr-7">
                  <input
                    checked={policy}
                    onChange={handlePolicy}
                    className="d-none"
                    type="checkbox"
                    id="newsletter-off"
                  />

                  <label for="newsletter-off" class="text-very-small"><span></span>I have read and understood <a href="/privacypolicy">The WorldGrad Privacy Policy </a></label>

                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-12 button-body plr-7">
                <input id="lead_captured_at" type="hidden" name="lead_captured_at" value="Website Enquiry"  onChange={handleChange} />
                  <button
                    type="button"
                    className="btn btn-theme btn-fast-yellow effect btn-md"
                    onClick={handleSubmit}
                    disabled={!policy}
                  >SUBMIT</button>
                </div></div>

            </div>
          </div>
        </div>


      </Modal>
    </>
  )
}

export default PopupForm
